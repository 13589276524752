@import '~normalize.css/normalize.css';

$background: #9e939b;

$french-lilac: rgba(150, 107, 157, 1);
$french-lilac-50: rgba(150, 107, 157, 0.5);
$platinum: rgba(234, 234, 234, 1);
$lavender-blue: rgba(203, 197, 234, 1);
$prussian-blue: rgba(49, 61, 90, 1);
$gunmetal: rgba(24, 54, 66, 1);

$treatments: #cbc0d3;

//$background: rgb(158,147,155);
$backgroundGrad: linear-gradient(140deg, rgba(158, 147, 155, 1) 0%, rgba(191, 178, 188, 1) 100%);

$white: #fff;
$whiteSemi: #ffffff50;

$outline: -1px 1px 0 $background, 1px 1px 0 $background, 1px -1px 0 $background,
  -1px -1px 0 $background;
$padding: 8px;

.pointer {
  cursor: pointer;
}

@font-face {
  font-family: 'Vera Humana 95';
  src: url('../../assets/VeraHumana95.woff2') format('woff2'),
    url('../../assets/VeraHumana95.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.page {
  width: 100%;
}

.container {
  background-color: $french-lilac;
  max-width: 1024px;
  margin: auto;
  gap: 0;
  nav {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    max-width: 1024px;
    margin: auto;

    border-bottom: 1px solid $whiteSemi;

    text-align: end;
    .icon {
      font-size: 2rem;
      padding: 8px;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        & {
          display: none;
        }
      }
      background-color: $background;
    }

    @media screen and (max-width: 768px) {
      &.visible {
        background-color: $background;
      }
    }

    ul {
      list-style: none;

      font-size: 2rem;
      background-color: $background;

      @media screen and (max-width: 768px) {
        &.hidden {
          display: none;
        }
      }

      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        font-size: inherit;
        background-color: inherit;
        visibility: inherit;
      }

      align-content: center;
      justify-content: space-around;
      margin: 0;
      padding: 0;
      li {
        border-left: 1px solid $whiteSemi;
        padding: 10px;
        overflow: hidden;
        flex: 1;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        color: $platinum;
        user-select: none;
        cursor: pointer;
        &:first-child {
          border-left: 0;
        }
        &:hover {
          @media screen and (min-width: 768px) {
            border-bottom: 5px solid $whiteSemi;
          }
          background-color: $whiteSemi;
        }
        &.active {
          @media screen and (min-width: 768px) {
            border-bottom: 5px solid $whiteSemi;
          }
        }
        @media screen and (max-width: 768px) {
          border: 0;
        }
      }
    }
  }
}

.scrolled {
  nav {
    @media screen and (min-width: 768px) {
      background-color: $background;
    }
    border-bottom: inherit;
  }
}

section {
  background-color: $background;
  // visibility: hidden;

  &.home {
    height: calc(100vh);
    //padding-top: 2rem;

    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    gap: 0;
    grid-template-areas: 'left mid mid mid mid right right' 'left mid mid mid mid right right';
    @media screen and (max-width: 768px) {
      grid-template-areas: 'left mid right right right right' 'left mid right right right right';
    }

    background-position: 30%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url('../../assets/hero_cur.png');
    box-sizing: border-box;
    color: $white;

    .left {
      grid-area: left;
      .banner {
        position: absolute;
        transform-origin: left top;
        width: calc(100vh - 64px);
        transform: rotate(-90deg) translateX(-100%);
        display: flex;

        flex-direction: column;

        //align-items: flex-end;
        text-shadow: $outline;
        font-size: 4rem;
        font-weight: 900;
        align-items: center;
        justify-content: center;
        div {
          margin-left: $padding;
          margin-top: $padding;
        }

        @media screen and (max-width: 768px) {
          flex-direction: row;
          font-size: 2rem;
        }

        font-family: 'Vera Humana 95', san-serif;
      }
    }

    .right {
      grid-area: right;
      border-left: 1px solid $whiteSemi;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
      .text {
        text-shadow: $outline;
        padding: 4rem 16px 16px;
        font-size: 1.25rem;
        @media screen and (max-width: 768px) {
          display: none;
        }
        @media screen and (max-height: 800px) {
          font-size: 1rem;
        }
        .signature2 {
          text-align: right;
        }
      }

      .social {
        grid-area: social;
        padding: 16px 16px 64px;

        a {
          padding-left: 16px;
        }
      }
    }

    .mid {
      border-left: 1px solid $whiteSemi;
      grid-area: mid;
      .number {
        font-family: 'Vera Humana 95', sans-serif;
        text-shadow: $outline;
        font-size: 10rem;
        line-height: 10rem;
        outline: 1px $background;
        @media screen and (max-width: 768px) {
          font-size: 7rem;
          line-height: 7rem;
        }
      }

      .absolute {
        position: absolute;
        transform: translateX(-20%) translateY(20%);
      }
    }
  }

  &.about,
  &.regulations,
  &.prices,
  &.treatments {
    font-size: 1.5rem;
    padding: 16px;
    background-color: $platinum;
    p {
      text-align: justify;
    }
  }

  &.prices {
    background-color: $treatments;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 1em;
      td {
        border-bottom: 1px $french-lilac-50 solid;
      }
    }
  }

  &.treatments {
    background-color: $treatments;
    .hotSpotMain {
      width: 100%;
      //min-width: 300px;
      margin: auto;
      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        z-index: -1;
        display: block;
      }
      .spot {
        position: absolute;
        display: block;
        font-size: 24px;
        user-select: none;
        cursor: pointer;
        padding: 10px;
      }
    }
  }

  &.contact {
    font-size: 1.5rem;
    padding: 16px;
    background-color: $background;
    text-align: justify;
    div.contact {
      display: flex;

      min-height: 50vh;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .map {
        flex: 1 0 0;
        iframe {
          display: block; /* iframes are inline by default */
          width: 100%;
          min-height: 300px;
        }
      }
      .address {
        flex: 1 0 0;
        padding: 16px;
        a {
          color: black;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Vera Humana 95', san-serif;
}
