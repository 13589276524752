@import 'node_modules/@fluentui/react/dist/sass/Fabric.scss';

/* SCSS RGB */
$french-lilac: rgba(150, 107, 157, 1);
$french-lilac-50: rgba(150, 107, 157, 0.5);
$platinum: rgba(234, 234, 234, 1);
$lavender-blue: rgba(203, 197, 234, 1);
$prussian-blue: rgba(49, 61, 90, 1);
$gunmetal: rgba(24, 54, 66, 1);
$gradient-right: linear-gradient(90deg, #966b9dff, #eaeaeaff);

/* SCSS RGB */
$space-cadet: rgba(34, 34, 59, 1);
$independence: rgba(74, 78, 105, 1);
$heliotrope-gray: rgba(154, 140, 152, 1);
$silver-pink: rgba(201, 173, 167, 1);
$seashell: rgba(242, 233, 228, 1);

.empty {
  background-color: white;
}

table.questionary {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid gray;
    td:first-child {
      width: 100%;
    }
    td,
    th {
      text-align: left;
      padding: 4px;
      font-size: 1rem;
    }
  }
}

.form {
  max-width: 400px;
  min-width: 300px;
  margin: auto;
}

.justified {
  text-align: justify;
  text-justify: inter-word;
}

.signature {
  background-color: lightgray;
  border: 1px solid gray;
  max-width: 20rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  height: 10rem;
}

.uploader {
  width: 100%;
  min-height: 100px;
  position: relative;
  border: 1px solid gray;

  &.modal {
    width: 300px;
    //height: '50px',
  }

  &.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: $ms-font-size-42;
    transform: translate(-50%, -50%);
    user-select: none;
    cursor: pointer;
  }
}

.photoPreview {
  min-height: 100px;
  max-height: 300px;
}

.paddingVertical {
  padding-top: 16px;
  padding-bottom: 16px;
}

html,
body {
  //height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
}

.fabric,
.vh100 {
  height: 100vh;
  //background: $platinum;
}

.header {
  background-color: $platinum;
  color: $gunmetal;
  padding: 2px;
  font-size: 1.7rem;
  font-weight: 900;
  user-select: none;
  text-decoration: none;
  text-transform: uppercase;
}

.content {
  padding: 4px;
  background-color: $seashell;
  .box {
    background-color: $platinum;
    margin: 1rem auto;
    padding: 2rem;
    max-width: 50em;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
    @media only screen and (max-width: 768px) {
      padding: 4px;
      max-width: 100%;
    }
  }
}

.invoice {
  padding: 4px;
  background-color: white;
  .box {
    margin: 0 auto 1rem;
    padding: 1rem;
    max-width: 50em;
    @media only screen and (max-width: 768px) {
      padding: 4px;
      max-width: 100%;
    }
  }
}

.headerNav {
  color: $gunmetal;
  font-size: $ms-font-size-16;
  font-weight: normal;
}

.footer {
  background-color: $platinum;
  color: $lavender-blue;
  font-size: $ms-font-size-12;
}

.sidebar {
  min-width: 250px;
  background-color: $lavender-blue;
  height: 100%;
  padding: 4px;
}

.hotSpot {
  width: 60%;
  min-width: 300px;
  margin: auto;
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
  .spot {
    position: absolute;
    display: block;
    font-size: 24px;
    user-select: none;
    cursor: pointer;
    padding: 10px;
  }
}

.spotsList {
  div {
    padding-bottom: 4px;
  }
}

.other {
  font-size: medium;
  padding-bottom: 8px;
}

.hsp {
  width: 100%;
  border: 1px solid $prussian-blue;
  background-color: white;
}

img.fit {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.stepTitle {
  font-size: 1.5rem;
  user-select: none;
  text-align: center;
}

.messageText {
  font-size: 1.2rem;
  user-select: none;
}

.question {
  font-size: 1rem;
}
.question-checkbox {
  padding-right: 32px;
}

.weekCell {
  background-color: $heliotrope-gray;
}

.uploadFile {
  border: 1px dashed $prussian-blue;
  background-color: white;
  .upCommand {
    background-color: $heliotrope-gray;
    color: $platinum;
    font-weight: 900;
    user-select: none;
    text-decoration: none;
    text-transform: uppercase;
    padding: 2px;
  }
}

.Page {
  min-width: 20rem;
  .navigation {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
    background-color: $silver-pink;
    .navLinks {
      padding-left: 1rem;
      padding-right: 1rem;
      a {
        color: $gunmetal;
        font-weight: 900;
        user-select: none;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

// resizing

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

@media (hover: none) {
  /* start mobile styles */
  select,
  textarea,
  input[type='”text”'],
  input[type='”password”'],
  input[type='”datetime”'],
  input[type='”datetime-local”'],
  input[type='”date”'],
  input[type='”month”'],
  input[type='”time”'],
  input[type='”week”'],
  input[type='”number”'],
  input[type='”email”'],
  input[type='”url”'],
  input[type='”tel”'] {
    font-size: 16px;
  }
  /* end mobile styles */
}
